import * as React from 'react'
import { styled } from '@mui/material/styles'
import type { BaseTextFieldProps } from '@mui/material/TextField'
import IconButton from './IconButton'
import { IconName } from '../utils/iconNameDict'
import { Box, InputAdornment, TextField as MuiTextField } from '@mui/material'
import Tooltip from './Tooltip'

export type TextFieldRef = {
	validate: () => boolean
	reset: () => void
}

const StyledIconButton = styled(IconButton)<{ color?: string }>(({ color }) => ({
	color: color || 'inherit',
}))

const TextField = React.forwardRef<
	TextFieldRef,
	{
		label?: string
		defaultValue?: string | number
		disabled?: boolean
		onChange?:
			| ((value: string | number, id?: string) => void)
			| React.Dispatch<React.SetStateAction<string>>
		m?: string
		variant?: BaseTextFieldProps['variant']
		flex?: boolean
		width?: string
		startLabel?: string
		startIconName?: IconName
		startIconNameColor?: string
		onClickStartIcon?: () => void
		endLabel?: string
		endIconName?: IconName
		backgroundColor?: string
		toolTip?: string
		type?: 'text' | 'number' | 'password'
		multiline?: boolean
		maxRows?: number
		placeHolder?: string
		onClickEndIcon?: () => void
		id?: string
	}
>(
	(
		{
			label,
			defaultValue,
			disabled,
			onChange,
			m,
			variant,
			flex,
			width,
			startLabel,
			startIconName,
			startIconNameColor,
			onClickStartIcon,
			endLabel,
			endIconName,
			backgroundColor,
			toolTip,
			type = 'text',
			multiline,
			maxRows,
			placeHolder,
			onClickEndIcon,
			id,
		},
		ref
	): JSX.Element => {
		const [value, setValue] = React.useState<string | number>(defaultValue || '')
		const [errorMessage, setErrorMessage] = React.useState<undefined | string>()

		const handleChange = React.useCallback(
			(eventOrValue) => {
				const newValue =
					typeof eventOrValue === 'string' ? eventOrValue : eventOrValue?.target?.value || ''

				const newValueWithoutLeadingZero = newValue.replace(/^0+/, '')
				const transformedValue =
					type === 'number'
						? Number.isNaN(Number(newValueWithoutLeadingZero))
							? 0
							: Number(newValueWithoutLeadingZero)
						: newValueWithoutLeadingZero
				setErrorMessage(undefined)
				setValue(transformedValue)
				onChange?.(transformedValue, id)
			},
			[id, onChange, type]
		)

		const inputProps = React.useMemo(() => {
			return startIconName || startLabel || toolTip
				? {
						startAdornment: startLabel ? (
							<InputAdornment position="start">{startLabel}</InputAdornment>
						) : startIconName ? (
							<InputAdornment position="start">
								<StyledIconButton
									onClick={onClickStartIcon}
									iconName={startIconName}
									color={startIconNameColor}
								/>
							</InputAdornment>
						) : toolTip ? (
							<InputAdornment position="start">
								<Tooltip width="40rem" text={toolTip} />
							</InputAdornment>
						) : null,
				  }
				: endIconName || endLabel
				? {
						endAdornment: endLabel ? (
							<InputAdornment position="start">{endLabel}</InputAdornment>
						) : endIconName ? (
							<InputAdornment position="start">
								<IconButton iconName={endIconName} onClick={onClickEndIcon} />
							</InputAdornment>
						) : null,
				  }
				: undefined
		}, [
			endIconName,
			endLabel,
			onClickEndIcon,
			onClickStartIcon,
			startIconName,
			startIconNameColor,
			startLabel,
			toolTip,
		])

		const mergedInputProps = React.useMemo(
			() => ({ style: { backgroundColor }, ...inputProps }),
			[backgroundColor, inputProps]
		)

		const handleValidate = React.useCallback(() => {
			let invalid = false
			if (!disabled && !value) {
				invalid = true
				setErrorMessage('Bitte ausfüllen')
			}
			return invalid
		}, [disabled, value])

		React.useImperativeHandle(
			ref,
			() => ({
				reset: () => handleChange(''),
				validate: handleValidate,
			}),
			[handleChange, handleValidate]
		)

		React.useEffect(() => {
			if (disabled) {
				setErrorMessage(undefined)
			}
		}, [disabled])

		return (
			<Box m={m} flex={flex ? '1' : undefined} width={width} ref={ref}>
				<MuiTextField
					label={label}
					value={value}
					fullWidth
					onChange={handleChange}
					InputProps={mergedInputProps}
					disabled={disabled}
					type={type}
					variant={variant}
					error={Boolean(errorMessage)}
					helperText={errorMessage}
					multiline={multiline}
					maxRows={maxRows}
					placeholder={placeHolder}
				/>
			</Box>
		)
	}
)

export default React.memo(TextField)
