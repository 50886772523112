import React from 'react'
import mapBoxGl from 'mapbox-gl'
import { Box } from '@mui/material'
import { MAPBOX_PUBLIC_TOKEN } from '../consts'
import Txt from '../components/Txt'
import { Statistic, useStatistics } from '../utils/statistics'
import SelectField from '../components/SelectField'

import chroma from 'chroma-js'
import { removeTypeName } from '../utils/helpers'
import StatisticCommunityCard from '../components/StatisticCommunityCard'
import MapContainer from '../components/MapContainer'
import ContentWrapper from '../components/ContentWrapper'
import { usePxPerRemFactor, useWindowDimensions } from '../utils/hooks'
import { APP_BAR_HEIGHT_REM } from '../components/AppBar'

mapBoxGl.accessToken = MAPBOX_PUBLIC_TOKEN!

type GermanState =
	| 'Baden-Württemberg'
	| 'Bayern'
	| 'Berlin'
	| 'Brandenburg'
	| 'Bremen'
	| 'Hamburg'
	| 'Hessen'
	| 'Mecklenburg-Vorpommern'
	| 'Niedersachsen'
	| 'Nordrhein-Westfalen'
	| 'Rheinland-Pfalz'
	| 'Saarland'
	| 'Sachsen'
	| 'Sachsen-Anhalt'
	| 'Schleswig-Holstein'
	| 'Thüringen'

const germanStates = [
	'Brandenburg',
	'Niedersachsen',
	'Sachsen',
	'Sachsen-Anhalt',
	'Thüringen',
	'Mecklenburg-Vorpommern',
	'Schleswig-Holstein',
	'Rheinland-Pfalz',
	'Saarland',
	'Hessen',
	'Nordrhein-Westfalen',
	'Baden-Württemberg',
	'Bayern',
]
const adjustedGermanStates = germanStates.map((s) => ({
	label: s,
	value: s,
}))

const filters = [
	'avgMinOrderValue',
	'euroOver10',
	'euroOver20',
	'euroOver30',
	'euroOver40',
	'euroOver50',
	'euroOver60',
	'euroOver70',
	'euroOver80',
	'euro20to30',
	'euro30to50',
	'euro50to80',
	'euro80to100',
	'unavailable',
]
type Filter =
	| 'avgMinOrderValue'
	| 'euroOver10'
	| 'euroOver20'
	| 'euroOver30'
	| 'euroOver40'
	| 'euroOver50'
	| 'euroOver60'
	| 'euroOver70'
	| 'euroOver80'
	| 'euro20to30'
	| 'euro30to50'
	| 'euro50to80'
	| 'euro80to100'
	| 'unavailable'

const adjustedFilters = filters.map((f) => ({
	label: f.includes('ratioOver') ? f.replace('ratioOver', 'Anteil Restaurants > ') + '€ MBW' : f,
	value: f,
}))

const red = '#ff0000'
const green = '#00FF00'
const yellow = '#FFFF00'
const orange = '#FFA500'
const greenToOrangeScale = chroma.scale([green, yellow, orange]) as chroma.Scale

const getColor = ({ value }: { filter: Filter; value: number | boolean }) => {
	return typeof value === 'boolean'
		? value === true
			? 'red'
			: 'green'
		: value === 1
		? red
		: greenToOrangeScale(value).hex()
}

const ScaleRender = ({ filter }: { filter: Filter }) => {
	const scale = React.useMemo(() => [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1], [])

	return (
		<Box
			display="flex"
			flexDirection="row"
			bgcolor="black"
			justifyContent="center"
			alignItems="center"
			p="0.5rem"
		>
			{scale.map((i) => (
				<Txt
					m="0 0.5rem 0 0"
					key={i * 10}
					color={getColor({
						filter,
						value: i,
					})}
				>
					{i}
				</Txt>
			))}
		</Box>
	)
}

const AnalyticsMapView = ({
	defaultLat = 51.0,
	defaultLon = 9.0,
	defaultZoom = 5.5,
	m,
}: {
	defaultLat?: number
	defaultLon?: number
	defaultZoom?: number
	m?: string
}): JSX.Element => {
	console.log('AnalyticsMapView')

	const windowDimensions = useWindowDimensions()
	const pxPerRem = usePxPerRemFactor()
	const mapHeight = windowDimensions.height - APP_BAR_HEIGHT_REM * pxPerRem

	const [state, setState] = React.useState<GermanState | undefined>('Brandenburg')
	const [filter, setFilter] = React.useState<Filter | undefined>('avgMinOrderValue')
	const [selectedStatistic, setSelectedStatistic] = React.useState<Statistic | undefined>(undefined)

	const statistics = useStatistics(state)

	const features = React.useMemo(
		() =>
			statistics?.map((s) => ({
				...s,
				color: getColor({
					filter: filter!,
					value: s[filter!],
				}),
				pointSize: 10,
			})),
		[filter, statistics]
	)

	const numberOfInhabitants = statistics
		?.filter((s) => s[filter] === 1)
		?.reduce((acc, s) => acc + s.inhabitants, 0)

	const numberOfCommunities = statistics?.filter((s) => s[filter] === 1).length

	const handleStateChange = React.useCallback((newState) => {
		setState(newState)
	}, [])

	const handleChangeFilter = React.useCallback((newFilter) => {
		setFilter(newFilter)
	}, [])

	const handleCloseSelectedStatistic = React.useCallback(() => {
		setSelectedStatistic(undefined)
	}, [])

	const handleClick = React.useCallback(
		(communityId) => {
			const result = removeTypeName(
				statistics?.find((s) => Number(s.id) === Number(communityId)) || {}
			) as Statistic
			console.log('result: ', result)
			setSelectedStatistic(result)
		},
		[statistics]
	)

	return (
		<ContentWrapper>
			<MapContainer
				features={features}
				onClickMapFeature={handleClick}
				defaultLat={defaultLat}
				defaultLon={defaultLon}
				defaultZoom={defaultZoom}
				width="100%"
				height={`${mapHeight}px`}
				m={m}
			>
				<>
					<Box
						position="absolute"
						left="1rem"
						top="1rem"
						zIndex="1000"
						bgcolor="white"
						boxShadow={2}
						borderRadius="0.5rem"
						display="flex"
						flexDirection="column"
						overflow="hidden"
					>
						<ScaleRender filter={filter!} />
						<Box p="1rem">
							{/* <Box display="flex" flexDirection="row" m="0 0 1rem 0">
								<SelectField
									width="20rem"
									label="Bundesland"
									onChange={handleStateChange}
									defaultValue={state}
									items={adjustedGermanStates}
								/>
							</Box> */}
							<Box display="flex" flexDirection="row">
								<Txt m="0 0 1rem 0" width="20rem">
									{numberOfInhabitants} Einwohner in {numberOfCommunities} Gemeinden
								</Txt>
							</Box>
							<Box display="flex" flexDirection="row">
								<SelectField
									width="20rem"
									label="Filter"
									onChange={handleChangeFilter}
									defaultValue={filter}
									items={adjustedFilters}
								/>
							</Box>
						</Box>
					</Box>
					{selectedStatistic ? (
						<StatisticCommunityCard
							statistic={selectedStatistic}
							onClose={handleCloseSelectedStatistic}
						/>
					) : null}
				</>
			</MapContainer>
		</ContentWrapper>
	)
}

export default AnalyticsMapView
