import { Box } from '@mui/material'
import React from 'react'
import Button from '../components/Button'
import ContentWrapper from '../components/ContentWrapper'
import { renderErrorDialog } from '../components/Dialog'
import SelectField from '../components/SelectField'
import TextField from '../components/TextField'
import login from '../mutations/login'
import { handleMutationError } from '../utils/errors'
import { fetchUser, setToken, useCurrentUser } from '../utils/user'
import EditRestaurantCommunities from '../components/EditRestaurantCommunities'
import EditRestaurantSlots from '../components/EditRestaurantSlots'
import RestaurantProducts from '../components/RestaurantProducts'

const overViewOptions = [
	{ label: 'Gemeinden', value: 'COMMUNITIES' },
	{ label: 'Lieferzeitpunkte', value: 'DELIVERY_SLOTS' },
	{ label: 'Kategorien, Produkte, Optionsgruppen', value: 'CATEGORIES' },
]

const EditRestaurant = (): JSX.Element => {
	const { data: currentUser } = useCurrentUser()

	const [selectedRestaurantId, setSelectedRestaurantId] = React.useState(null)
	const [view, setView] = React.useState('COMMUNITIES')

	const handleSelectRestaurant = React.useCallback((restaurant) => {
		setSelectedRestaurantId(restaurant)
	}, [])

	const handleSelectView = React.useCallback((val) => {
		setView(val)
	}, [])

	const adminRestaurans = currentUser?.adminRestaurans
	const editableRestaurants = React.useMemo(
		() => adminRestaurans.map((item) => ({ label: item.title, value: item.id })),
		[adminRestaurans]
	)

	const selectedRestaurantCommunities = React.useMemo(
		() =>
			!selectedRestaurantId
				? []
				: adminRestaurans.find((r) => r.id === selectedRestaurantId)?.communities,
		[selectedRestaurantId, adminRestaurans]
	)

	const selectedRestaurantSlots = React.useMemo(
		() =>
			!selectedRestaurantId
				? []
				: adminRestaurans.find((r) => r.id === selectedRestaurantId)?.slots,
		[selectedRestaurantId, adminRestaurans]
	)

	const selectedRestaurantCategories = React.useMemo(
		() =>
			!selectedRestaurantId
				? []
				: adminRestaurans.find((r) => r.id === selectedRestaurantId)?.categories,
		[selectedRestaurantId, adminRestaurans]
	)

	return (
		<>
			<ContentWrapper p="1rem">
				<Box flexDirection="row" display="flex" justifyContent="center">
					<SelectField
						label="Restaurant wählen"
						items={editableRestaurants}
						m="1rem"
						onChange={handleSelectRestaurant}
						width="20rem"
					/>
					{!selectedRestaurantId ? null : (
						<SelectField
							defaultValue={view}
							label="Ansicht wählen"
							items={overViewOptions}
							m="1rem"
							onChange={handleSelectView}
							width="20rem"
						/>
					)}
				</Box>
				{view === 'COMMUNITIES' ? (
					<EditRestaurantCommunities
						communities={selectedRestaurantCommunities}
						restaurantId={selectedRestaurantId}
					/>
				) : view === 'DELIVERY_SLOTS' ? (
					<EditRestaurantSlots
						slots={selectedRestaurantSlots}
						restaurantId={selectedRestaurantId}
					/>
				) : view === 'CATEGORIES' ? (
					<RestaurantProducts categories={selectedRestaurantCategories} />
				) : null}
			</ContentWrapper>
		</>
	)
}

const RestaurantViewAuthorizerWrapper = (): JSX.Element => {
	const { data: currentUser } = useCurrentUser()
	console.log('currentUser: ', currentUser)

	const [loading, setLoading] = React.useState(false)
	const [email, setEmail] = React.useState('')
	const [password, setPassword] = React.useState('')

	const handleSaveJwtAndFetchUser = React.useCallback(async (jwt) => {
		try {
			setToken(jwt)
			await fetchUser()
		} catch (e) {
			renderErrorDialog(e.message)
		}
	}, [])

	const handleLogin = React.useCallback(async () => {
		setLoading(true)
		const { errors, jwt } = await login(email, password)
		setLoading(false)
		if (errors) {
			handleMutationError('login', errors)
		} else {
			handleSaveJwtAndFetchUser(jwt)
		}
	}, [email, handleSaveJwtAndFetchUser, password])

	return currentUser === undefined ? (
		<ContentWrapper />
	) : currentUser === null ? (
		<ContentWrapper>
			<Box m="1rem" display="flex" flexDirection="row" width="100%" alignItems="center">
				<Box flex="1" />
				<TextField
					m="0 1rem 0 0"
					label="Email"
					backgroundColor="white"
					onChange={setEmail}
					defaultValue={email}
					width="20rem"
				/>
				<TextField
					defaultValue={password}
					m="0 1rem 0 0"
					label="Passwort"
					backgroundColor="white"
					onChange={setPassword}
					width="20rem"
					type="password"
				/>
				<Button variant="outlined" onClick={handleLogin} loading={loading}>
					Login
				</Button>
				<Box flex="1" />
			</Box>
		</ContentWrapper>
	) : (
		<EditRestaurant />
	)
}
export default RestaurantViewAuthorizerWrapper
