import { Box } from '@mui/material'
import React from 'react'
import ContentWrapper from '../components/ContentWrapper'
import EditableTable from '../components/EditableTable'

import { Category } from '../utils/category'

const dict = {
	categoryName: 'Kategorie',
	categoryDescription: 'Kategorie Beschreibung',
	productName: 'Produkt',
	productDescription: 'Produkt Beschreibung',
	productPriceLabel: 'Produkt Preis',
	productVariantName: 'Variante Name',
	productVariantPriceLabel: 'Variante Preis',
	productVariantAlcoholVolumeMl: 'Alkohol Volumen',
	productVariantCaffeineAmountMg: 'Koffein Menge',
	optionGroups: 'Optionsgruppen',
}

const RestaurantProducts = ({ categories }: { categories: Category[] }): JSX.Element => {
	const categoriesWithProducts = React.useMemo(() => {
		return categories
			.map((c) =>
				c.products
					.map((p) =>
						p.variants
							.map((v) => ({
								title: `${c.id}:${p.id}:${v.id}`,
								id: `${c.id}:${p.id}:${v.id}`,
								categoryId: c.id,
								categoryName: c.name,
								categoryDescription: c.description,
								productId: p.id,
								productName: p.name,
								productDescription: p.description,
								productPriceInCents: p.priceInCents,
								productPriceLabel: p.priceLabel,
								productVariantId: v.id,
								productVariantName: v.name || '',
								productVariantPriceInCents: !v.name ? '' : v.priceInCents,
								productVariantPriceLabel: !v.name ? '' : v.priceLabel,
								productVariantAlcoholVolumeMl: v.alcoholVolumeMl || '',
								productVariantCaffeineAmountMg: v.caffeineAmountMg || '',
								optionGroups: v.optionGroups.map((og) => og.name).join(', '),
							}))
							.flat()
					)
					.flat()
			)
			.flat()
	}, [categories])

	console.log('categoriesWithProducts', categoriesWithProducts)

	return (
		<>
			<ContentWrapper p="1rem">
				<Box maxWidth="100%" m="1rem">
					{!categories || !categories.length ? null : (
						<EditableTable objects={categoriesWithProducts} dict={dict} />
					)}
				</Box>
			</ContentWrapper>
		</>
	)
}

export default RestaurantProducts
