import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { APP_BAR_ZINDEX } from '../consts'
import Logo from './Logo'

export const APP_BAR_HEIGHT_REM = 4.5

const AppBar = ({ children }: { children?: JSX.Element }) => {
	const { contentMaxWidthPx } = useTheme()

	return (
		<Box
			width="100%"
			height={`${APP_BAR_HEIGHT_REM}rem`}
			boxShadow={1}
			zIndex={APP_BAR_ZINDEX}
			display="flex"
			flexDirection="row"
			justifyContent="center"
		>
			<Box
				flex="1"
				maxWidth={`${contentMaxWidthPx}px`}
				maxHeight="100%"
				display="flex"
				flexDirection="row"
				alignItems="center"
				p="0 1rem"
			>
				<Logo m="0 1rem 0 0" type="LOGO" navigateTo="/" maxWidth="3rem" />
				<Logo type="TEXT" navigateTo="/" maxWidth="8rem" />
				{children}
			</Box>
		</Box>
	)
}
export default React.memo(AppBar)
