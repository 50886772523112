import { gql } from '@apollo/client'
import client from '../utils/client'
import { RestaurantSlotDetailsFragment } from '../utils/fragments'
import { MutationHandlerResult } from '../utils/errors'

const updateRestaurantSlot = async ({
	type,
	restaurantId,
	slotId,
	day,
	hour,
	minute,
}: {
	type: 'DELETE' | 'UPDATE' | 'CREATE'
	restaurantId: string
	slotId?: string
	day?: number
	hour?: number
	minute?: number
}): Promise<MutationHandlerResult> => {
	console.log('updateRestaurantSlot', {
		type,
		restaurantId,
		slotId,
		day,
		hour,
		minute,
	})

	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation UpdateRestaurantSlot(
					$type: UpdateType!
					$restaurantId: ID!
					$slotId: ID
					$day: Day
					$hour: Int
					$minute: Int
				) {
					updateRestaurantSlot(
						type: $type
						restaurantId: $restaurantId
						slotId: $slotId
						day: $day
						hour: $hour
						minute: $minute
					) {
						...RestaurantSlotDetailsFragment
					}
				}
				${RestaurantSlotDetailsFragment}
			`,
			variables: {
				type,
				restaurantId,
				slotId,
				day,
				hour,
				minute,
			},
			refetchQueries: ['CurrentUser'],
		})

		if (errors) {
			return { success: false, errors }
		}
		return {
			success: true,
			errors: undefined,
		}
	} catch (e) {
		if (e && e.networkError) return { success: false, errors: 'NETWORK_ERROR' }
		throw e
	}
}

export default updateRestaurantSlot
