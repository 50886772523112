import React from 'react'
import ContentWrapper from './ContentWrapper'
import { Box } from '@mui/material'
import Txt from './Txt'
import TextField from './TextField'
import SelectField from './SelectField'
import Button from './Button'

const CreateDialog = ({
	title,
	config,
	onSubmit,
	dismissPortal,
}: {
	title: string
	config: {
		property: string
		type: 'select' | 'number' | 'string'
		options?: string[]
		label: string
	}[]
	onSubmit: (obj: any) => Promise<void>
	dismissPortal: () => void
}) => {
	const [loading, setLoading] = React.useState(false)
	const [state, setState] = React.useState({})

	const handleChange = React.useCallback((value, id) => {
		setState((prev) => ({ ...prev, [id]: value }))
	}, [])

	const handleSave = React.useCallback(async () => {
		setLoading(true)
		await onSubmit(state)
		setLoading(false)
		dismissPortal()
	}, [dismissPortal, onSubmit, state])

	return (
		<ContentWrapper>
			<Box display="flex" flexDirection="column" width="30rem" p="1rem 2rem 2rem 2rem">
				<Box display="flex" flexDirection="row" p="1rem" justifyContent={'center'}>
					<Txt variant="h4">{title}</Txt>
				</Box>
				{config.map(({ property, type, options, label }) =>
					type === 'string' || type === 'number' ? (
						<TextField
							key={property}
							label={label}
							m="1rem 0"
							defaultValue={state[property] as string | number}
							id={property}
							onChange={handleChange}
							type={type === 'string' ? 'text' : 'number'}
						/>
					) : (
						<SelectField
							key={property}
							label={label}
							items={options.map((option) => ({ label: option, value: option }))}
							m="1rem"
							onChange={(value) => handleChange(value, property)}
						/>
					)
				)}

				<Box display="flex" flexDirection="row" justifyContent="flex-end" m="1rem 0 0 0">
					<Button variant="outlined" m="0 1rem 0 0" onClick={dismissPortal} loading={loading}>
						Schließen
					</Button>
					<Button variant="outlined" m="0 0 0 0" onClick={handleSave} loading={loading}>
						Speichern
					</Button>
				</Box>
			</Box>
		</ContentWrapper>
	)
}

export default CreateDialog
