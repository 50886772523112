import React from 'react'
import mapBoxGl from 'mapbox-gl'
import { Box } from '@mui/material'
import { MAPBOX_PUBLIC_TOKEN } from '../consts'

mapBoxGl.accessToken = MAPBOX_PUBLIC_TOKEN!

const layerId = 'layerId'

const MapContainer = ({
	defaultLat = 51.0,
	defaultLon = 9.0,
	defaultZoom = 5.5,
	width,
	height,
	m,
	features,
	onClickMapFeature,
	children,
}: {
	defaultLat?: number
	defaultLon?: number
	defaultZoom?: number
	width?: string
	height?: string
	m?: string
	features: {
		lat: number
		lon: number
		color: string
		id: string
		pointSize: number
	}[]
	onClickMapFeature: (id: string) => void
	children?: JSX.Element
}): JSX.Element => {
	const mapContainer = React.useRef<any>(null)
	const map = React.useRef<any>(null)

	const [loaded, setLoaded] = React.useState(false)

	React.useEffect(() => {
		const geoGsonSource = map.current?.getSource(layerId)
		if (loaded && geoGsonSource && features && features.length) {
			geoGsonSource.setData({
				type: 'FeatureCollection',
				features: features.map((f) => ({
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [f.lon, f.lat],
					},
					properties: {
						'id': f.id,
						'marker-color': f.color,
						'marker-size': f.pointSize,
					},
				})),
			})
			map.current.on('click', layerId, (e) => {
				const id = e.features[0].properties.id
				onClickMapFeature(id)
			})
		}
	}, [features, onClickMapFeature, loaded])

	React.useEffect(() => {
		if (!map.current) {
			map.current = new mapBoxGl.Map({
				container: mapContainer.current,
				style: 'mapbox://styles/mapbox/light-v11',
				center: [defaultLon, defaultLat],
				zoom: defaultZoom,
			})
			map.current.on('load', () => {
				map.current.addLayer({
					id: layerId,
					type: 'circle',
					source: {
						type: 'geojson',
						data: {
							type: 'FeatureCollection',
							features: [],
						},
					},
					paint: {
						'circle-radius': ['get', 'marker-size'],
						'circle-color': ['get', 'marker-color'],
					},
				})
				setLoaded(true)
			})
		}
	}, [defaultLat, defaultLon, defaultZoom])

	return (
		<Box ref={mapContainer} m={m} height={height} width={width} position="relative">
			{children}
		</Box>
	)
}

export default MapContainer
