import React from 'react'
import { Box } from '@mui/material'
import Txt from './Txt'
import { Statistic } from '../utils/statistics'
import Button from './Button'

const SpaceBetweenRow = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
	return (
		<Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row">
			{children}
		</Box>
	)
}

const StatisticCommunityCard = ({
	statistic,
	onClose,
}: {
	statistic: Statistic
	onClose: () => void
}) => {
	return (
		<Box
			position="absolute"
			right="1rem"
			bottom="1rem"
			zIndex="1000"
			bgcolor="white"
			boxShadow={2}
			borderRadius="0.5rem"
			display="flex"
			flexDirection="column"
			overflow="scroll"
			width="20rem"
			height="20rem"
			p="1rem"
		>
			<Box position="absolute" right="0" top="0">
				<Button iconName="CloseOutlined" onClick={onClose} />
			</Box>

			<Txt m="0 0 0.5rem 0" fontWeight="bold">
				{statistic.name}
			</Txt>

			<Txt>
				<SpaceBetweenRow>
					<Txt m="0 0 0.5rem 0">Einwohner</Txt>
					<Txt>{statistic.inhabitants}</Txt>
				</SpaceBetweenRow>
				<SpaceBetweenRow>
					<Txt m="0 0 0.5rem 0">Größe</Txt>
					<Txt>{statistic.squareKilometers + ' km'}</Txt>
				</SpaceBetweenRow>
				<Box border="1px solid gainsboro" p="0.5rem">
					{statistic.restaurants
						.slice()
						.sort((a, b) => (a.lfMinOrderValue > b.lfMinOrderValue ? -1 : 1))
						.map((r, i) => (
							<Box key={i} display="flex" alignItems="center" flexDirection="row">
								<Txt m="0 1rem 0 0">{r.lfMinOrderValue / 100 + ' €'}</Txt>
								<Txt m="0 1rem 0 0">{r.score + ' 😊'}</Txt>
								<Txt m="0 1rem 0 0" navigateToExternal navigateTo={`${r.link}`}>
									Link
								</Txt>
							</Box>
						))}
				</Box>
			</Txt>
		</Box>
	)
}

export default StatisticCommunityCard
