import React from 'react'
import { ApolloQueryResult, gql, useQuery } from '@apollo/client'

import { UserInfoFragment } from './fragments'
import client from './client'
import { Restaurant } from './restaurant'

export type CurrentUser = {
	id: string
	email: string
	adminRestaurans: Restaurant[]
	isRestaurantAdmin: boolean
}

let token = ''

export const getToken = () => {
	return token
}

export const setToken = (jwt: string) => {
	token = jwt
}

export const CURRENT_USER_QUERY = gql`
	query CurrentUser {
		currentUser {
			...UserInfoFragment
		}
	}
	${UserInfoFragment}
`

export const fetchUser = async (): Promise<null | CurrentUser> => {
	const { data } = await client.query({
		query: CURRENT_USER_QUERY,
		fetchPolicy: 'network-only',
	})
	return data.currentUser
}

export const logout = async () => {
	setToken('')
	await client.resetStore()
}

export const useCurrentUser = (props?: {
	skip?: boolean
}): {
	data: undefined | null | CurrentUser
	refetch: () => Promise<ApolloQueryResult<CurrentUser>>
} => {
	const { data, refetch } = useQuery(CURRENT_USER_QUERY, {
		skip: props?.skip,
	})

	return React.useMemo(() => ({ data: data?.currentUser, refetch }), [data?.currentUser, refetch])
}
