import React from 'react'

import { CircularProgress } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Txt, { TxtProps } from './Txt'

export type SimpleTableObject = { id: string; [key: string]: unknown }
type CellObject = { id: string; text?: string; jsx?: JSX.Element; isEditable?: boolean }

const StatfulTableCell = ({
	id,
	text,
	jsx,
	numberOfLines,
	textVariant,
}: {
	id: string
	text?: string
	jsx?: JSX.Element
	numberOfLines?: number
	textVariant?: TxtProps['variant']
}) => {
	return (
		<TableCell key={id}>
			{text ? (
				<Txt numberOfLines={numberOfLines} variant={textVariant} noUnderLine>
					{text}
				</Txt>
			) : (
				jsx
			)}
		</TableCell>
	)
}

const Row = ({
	cells,
	textVariant,
	numberOfLines,
}: {
	cells: CellObject[]
	textVariant?: TxtProps['variant']
	numberOfLines?: number
	onChange?: (id: string, value: string) => Promise<void>
}) => {
	return (
		<TableRow>
			{cells.map(({ id, text, jsx }) => (
				<StatfulTableCell
					key={id}
					id={id}
					text={text}
					jsx={jsx}
					numberOfLines={numberOfLines}
					textVariant={textVariant}
				/>
			))}
		</TableRow>
	)
}

const SimpleTable3 = ({
	objects,
	renderActions,
	activeKeys,
	inactiveKeys,
	numberOfLines,
	dict,
}: {
	objects: SimpleTableObject[]
	renderActions?: (props) => JSX.Element
	activeKeys?: string[]
	inactiveKeys?: string[]
	numberOfLines?: number
	dict?: Record<string, string>
}) => {
	const mergedInactiveKeys = React.useMemo(
		() => (!inactiveKeys ? ['__typename'] : [...inactiveKeys, '__typename']),
		[inactiveKeys]
	)

	const tableData = React.useMemo(() => {
		const headData = objects
			? ([
					...Object.keys(objects[0] || {})
						.filter((key) =>
							!activeKeys ? true : activeKeys.some((activeKey) => activeKey === key)
						)
						.filter((key) =>
							!mergedInactiveKeys
								? true
								: !mergedInactiveKeys.some((inactiveKey) => inactiveKey === key)
						)
						.map((key) => ({ id: key, text: dict[key] || key })),
					renderActions ? { id: 'actions', text: 'Aktionen' } : undefined,
			  ].filter(Boolean) as CellObject[])
			: []
		const rowsData = objects?.map(
			(object) =>
				[
					...Object.entries(object)
						.filter(([key]) =>
							!activeKeys ? true : activeKeys.some((activeKey) => activeKey === key)
						)
						.filter(([key]) =>
							!mergedInactiveKeys
								? true
								: !mergedInactiveKeys.some((inactiveKey) => inactiveKey === key)
						)
						.map(([_, value], index) => ({
							id: `${object.id}:${index}`,
							text: String(value),
						})),
					renderActions ? { id: 'actions', jsx: renderActions?.(object.id) } : undefined,
				].filter(Boolean) as CellObject[]
		)
		return headData && rowsData ? { head: headData, rows: rowsData } : undefined
	}, [activeKeys, dict, mergedInactiveKeys, objects, renderActions])

	return !tableData ? (
		<CircularProgress />
	) : (
		<TableContainer>
			<Table>
				<TableHead>
					<Row cells={tableData.head} />
				</TableHead>

				<TableBody>
					{tableData.rows?.map((row, index) => (
						<Row key={index} cells={row} numberOfLines={numberOfLines} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
export default React.memo(SimpleTable3)
