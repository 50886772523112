import React from 'react'

const emailRegExp = new RegExp(
	/^[_A-Za-z0-9-]+([.|+][_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/
)

export const isValidEmail = (value?: string): boolean => {
	if (!value) return false
	return emailRegExp.test(value)
}

export const removeTypeName = (input: { [key: string]: unknown }): { [key: string]: unknown } => {
	return Object.entries(input)
		.filter(([key]) => key !== '__typename')
		.reduce((acc, next) => ({ ...acc, [next[0]]: next[1] }), {})
}

export const useLabelAndValue = ({
	items,
	labelKey,
	valueKey,
}: {
	items?: { [key: string]: string | unknown }[] | null
	labelKey: string
	valueKey: string
}): { label: string; value: string }[] | undefined => {
	return React.useMemo(
		() =>
			items?.map(
				(item) =>
					({
						label: typeof item[labelKey] === 'string' ? item[labelKey] : '?unknown',
						value: typeof item[valueKey] === 'string' ? item[valueKey] : '?unknown',
					} as { label: string; value: string })
			),
		[items, labelKey, valueKey]
	)
}

export const useStringArray = (itemOrItems?: string[] | string): string[] => {
	const arrayed = React.useMemo(
		() => (!itemOrItems ? [] : Array.isArray(itemOrItems) ? itemOrItems : [itemOrItems]),
		[itemOrItems]
	)
	return arrayed
}
