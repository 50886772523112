import { Box } from '@mui/material'
import ContentWrapper from '../components/ContentWrapper'
import EditableTable from '../components/EditableTable'
import { Community } from '../utils/community'
import React from 'react'
import updateRestaurantCommunity from '../mutations/updateRestaurantCommunity'

const dict = {
	title: 'Gemeinde',
	parentRestaurantDeliveryFeeDefaultInCents: 'Gesamt-Liefergebühren (in Cent)',
	parentRestaurantMinOrderValueInCents: 'Gesamt-Mindestbestellwert (in Cent)',
}

const EditRestaurantCommunities = ({
	communities,
	restaurantId,
}: {
	communities: Community[]
	restaurantId: string
}): JSX.Element => {
	const handleEdit = React.useCallback(
		async (obj) => {
			await updateRestaurantCommunity({
				type: 'UPDATE',
				restaurantId,
				communityId: obj.id,
				customMinOrderValueInCents: obj.parentRestaurantMinOrderValueInCents,
				customDeliveryFeeDefaultInCents: obj.parentRestaurantDeliveryFeeDefaultInCents,
			})
		},
		[restaurantId]
	)

	const handleDelete = React.useCallback(
		async (obj) => {
			await updateRestaurantCommunity({
				type: 'DELETE',
				restaurantId,
				communityId: obj.id,
			})
		},
		[restaurantId]
	)

	return (
		<>
			<ContentWrapper p="1rem">
				<Box maxWidth="100%" m="1rem">
					{!communities || !communities.length ? null : (
						<EditableTable
							objects={communities}
							dict={dict}
							onEdit={handleEdit}
							onDelete={handleDelete}
						/>
					)}
				</Box>
			</ContentWrapper>
		</>
	)
}

export default EditRestaurantCommunities
