import { gql } from '@apollo/client'

export const CommunityDetailsFragment = gql`
	fragment CommunityDetailsFragment on Community {
		id
		title
		lat
		lon
		parentRestaurantMinOrderValueInCents
		parentRestaurantDeliveryFeeDefaultInCents
	}
`

export const RestaurantSlotDetailsFragment = gql`
	fragment RestaurantSlotDetailsFragment on RestaurantSlot {
		id
		day
		hour
		minute
	}
`

export const CategoryDetailsFragment = gql`
	fragment CategoryDetailsFragment on Category {
		id
		name
		description
		products {
			id
			name
			description
			priceInCents
			priceLabel
			variants {
				id
				name
				priceInCents
				priceLabel
				optionGroups {
					id
					name
					isTypeMulti
					isRequired
					minChoices
					maxChoices
					options {
						id
						name
						minAmount
						maxAmount
						priceInCents
						priceLabel
					}
				}
			}
		}
	}
`

export const RestaurantInfoFragment = gql`
	fragment RestaurantInfoFragment on Restaurant {
		id
		title
		communities {
			...CommunityDetailsFragment
		}
		slots {
			...RestaurantSlotDetailsFragment
		}
		categories {
			...CategoryDetailsFragment
		}
	}
	${CommunityDetailsFragment}
	${RestaurantSlotDetailsFragment}
	${CategoryDetailsFragment}
`

export const UserInfoFragment = gql`
	fragment UserInfoFragment on User {
		id
		email
		adminRestaurans {
			...RestaurantInfoFragment
		}
		isRestaurantAdmin
	}
	${RestaurantInfoFragment}
`

export default null
