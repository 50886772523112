import React from 'react'
import { gql, useQuery } from '@apollo/client'

export type Statistic = {
	id: string
	lat: number
	lon: number
	name: string
	inhabitants: number
	squareKilometers: number
	avgMinOrderValue: number
	euroOver10: number
	euroOver20: number
	euroOver30: number
	euroOver40: number
	euroOver50: number
	euroOver60: number
	euroOver70: number
	euroOver80: number
	euro30to50: number
	euro50to80: number
	euro80to100: number
	unavailable: boolean
	restaurants: {
		lfMinOrderValue: number
		lfDeliveryFeeDefault: number
		score: number
		votes: number
		link: string
	}[]
}

export const STATISTICS_QUERY = gql`
	query Statistics($state: String!) {
		statistics(state: $state) {
			id
			lat
			lon
			name
			inhabitants
			squareKilometers
			avgMinOrderValue
			euroOver10
			euroOver20
			euroOver30
			euroOver40
			euroOver50
			euroOver60
			euroOver70
			euroOver80
			euro30to50
			euro50to80
			euro80to100
			unavailable
			restaurants {
				lfMinOrderValue
				lfDeliveryFeeDefault
				score
				votes
				link
			}
		}
	}
`

export const useStatistics = (state?: string): null | undefined | Statistic[] => {
	const { data } = useQuery(STATISTICS_QUERY, {
		variables: { state, skip: !state },
	})
	return React.useMemo(() => (!state ? null : data?.statistics), [data?.statistics, state])
}

export type RelevantRestaurant = {
	id: string
	name: string
	score: number
	votes: number
	link: string
	communities: {
		name: string
		inhabitants: number
		avgMinOrderValue: number
	}[]
	allInhabitants: number
}

export const RELEVANT_RESTAURANT_QUERY = gql`
	query RelevantRestaurants(
		$ratioOverType: String!
		$ratioOverValue: Float!
		$state: String!
		$relevantRestaurantMinOrderValue: Int!
	) {
		relevantRestaurants(
			ratioOverType: $ratioOverType
			ratioOverValue: $ratioOverValue
			state: $state
			relevantRestaurantMinOrderValue: $relevantRestaurantMinOrderValue
		) {
			id
			name
			score
			votes
			link
			communities {
				name
				inhabitants
				avgMinOrderValue
			}
			allInhabitants
		}
	}
`

export const useRelevantRestaurants = ({
	ratioOverType,
	ratioOverValue,
	state,
	relevantRestaurantMinOrderValue,
	skip,
}: {
	ratioOverType: string
	ratioOverValue: number
	state: string
	relevantRestaurantMinOrderValue: number
	skip?: boolean
}): null | undefined | RelevantRestaurant[] => {
	const { data } = useQuery(RELEVANT_RESTAURANT_QUERY, {
		variables: {
			ratioOverType,
			ratioOverValue,
			state,
			relevantRestaurantMinOrderValue,
			skip: skip,
		},
	})
	return React.useMemo(
		() => (skip ? null : data?.relevantRestaurants),
		[data?.relevantRestaurants, skip]
	)
}
