import client from '../utils/client'
import { gql } from '@apollo/client'
import { CommunityDetailsFragment } from '../utils/fragments'

type Error = 'NETWORK_ERROR' | 'INVALID_EMAIL_ADDRES' | 'INVALID_LOCATION_ID'

const updateRestaurantCommunity = async ({
	type,
	restaurantId,
	communityId,
	customMinOrderValueInCents,
	customDeliveryFeeDefaultInCents,
}: {
	type: 'DELETE' | 'UPDATE'
	restaurantId: string
	communityId: string
	customMinOrderValueInCents?: number
	customDeliveryFeeDefaultInCents?: number
}): Promise<{ success: true; error: undefined } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation UpdateRestaurantCommunity(
					$type: UpdateType!
					$restaurantId: ID!
					$communityId: ID!
					$customMinOrderValueInCents: Int
					$customDeliveryFeeDefaultInCents: Int
				) {
					updateRestaurantCommunity(
						type: $type
						restaurantId: $restaurantId
						communityId: $communityId
						customMinOrderValueInCents: $customMinOrderValueInCents
						customDeliveryFeeDefaultInCents: $customDeliveryFeeDefaultInCents
					) {
						...CommunityDetailsFragment
					}
				}
				${CommunityDetailsFragment}
			`,
			variables: {
				type,
				restaurantId,
				communityId,
				customMinOrderValueInCents,
				customDeliveryFeeDefaultInCents,
			},
			refetchQueries: type === 'DELETE' ? ['CurrentUser'] : [],
		})
		if (errors) {
			return { success: false, error: errors[0]?.message as Error }
		}
		return {
			success: true,
			error: undefined,
		}
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' }
		throw e
	}
}

export default updateRestaurantCommunity
