/*eslint-disable react-perf/jsx-no-jsx-as-prop */
import { Route } from 'react-router-dom'

import HomeView from './screens/HomeView'
import ImpressView from './screens/ImpressView'
import NotFoundView from './screens/NotFoundView'
import EditRestaurant from './screens/EditRestaurant'
import AnalyticsMapView from './screens/AnalyticsMapView'
import { DEVELOPMENT } from './consts'
type Route = {
	path: string
	element: JSX.Element
}

export const routes: Route[] = [
	{
		path: '/',
		element: <HomeView />,
	},
	{
		path: '/impress',
		element: <ImpressView />,
	},
	{
		path: '/restaurant',
		element: <EditRestaurant />,
	},
	{
		path: '*',
		element: <NotFoundView />,
	},
	...(DEVELOPMENT
		? [
				{
					path: '/map',
					element: <AnalyticsMapView />,
				},
		  ]
		: []),
]

type UserType = 'LOGGED_OUT'

const getRoutes = (type?: UserType) => {
	return type === 'LOGGED_OUT' ? routes : []
}

export default getRoutes
