import { GraphQLFormattedError } from 'graphql'
import Dialog from '../components/Dialog'

export type MutationHandlerError =
	| readonly GraphQLFormattedError[]
	| 'NETWORK_ERROR'
	| string
	| null
	| undefined
export type MutationHandlerSuccessResult = { success: true; errors: null }
export type MutationHandlerErrorResult = { success: false; errors: MutationHandlerError }
export type MutationHandlerResult = MutationHandlerSuccessResult | MutationHandlerErrorResult

export const handleMutationError = (operation: string, errors: MutationHandlerError): void => {
	if (!errors) {
		return
	}

	const errorCode = typeof errors === 'string' ? undefined : errors[0].extensions?.code
	const errorMessage = typeof errors === 'string' ? errors : errors[0].message

	// will be handled by the global error handler
	if (errorCode === 'INTERNAL_SERVER_ERROR') {
		return
	}

	if (errorMessage === 'NETWORK_ERROR') {
		Dialog.render({
			title: 'Netzwerkfehler',
			description: 'Bitte überprüfe deine Internetverbindung und versuche es erneut.',
		})
		return
	}

	Dialog.render({
		title: `Fehler`,
		description: `${errorMessage}.`,
	})
}
