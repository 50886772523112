import { Box } from '@mui/material'
import React from 'react'
import ContentWrapper from '../components/ContentWrapper'
import Dialog from '../components/Dialog'
import EditableTable from '../components/EditableTable'
import updateRestaurantSlot from '../mutations/updateRestaurantSlot'
import { handleMutationError } from '../utils/errors'

import { RestaurantSlot } from '../utils/restaurantSlot'
import CreateDialog from './CreateDialog'

const config = [
	{
		label: 'Tag',
		property: 'day',
		type: 'select' as const,
		options: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],
	},
	{
		label: 'Stunde',
		property: 'hour',
		type: 'select' as const,
		options: Array.from({ length: 24 }, (_, i) => i.toString()),
	},
	{
		label: 'Minute',
		property: 'minute',
		type: 'select' as const,
		options: ['00', '15', '30', '45'],
	},
]

const dict = {
	title: 'Titel',
	day: 'Tag',
	hour: 'Stunde',
	minute: 'Minute',
}

const dayOrder = {
	MONDAY: 1,
	TUESDAY: 2,
	WEDNESDAY: 3,
	THURSDAY: 4,
	FRIDAY: 5,
	SATURDAY: 6,
	SUNDAY: 7,
}

const EditRestaurantSlots = ({
	slots,
	restaurantId,
}: {
	slots: RestaurantSlot[]
	restaurantId: string
}): JSX.Element => {
	const handleDelete = React.useCallback(
		async (obj) => {
			const { errors } = await updateRestaurantSlot({
				type: 'DELETE',
				restaurantId,
				slotId: obj.id,
			})
			if (errors) {
				handleMutationError('updateRestaurantSlot', errors)
			}
		},
		[restaurantId]
	)

	const handleCreate = React.useCallback(
		async (obj) => {
			const { errors } = await updateRestaurantSlot({
				type: 'CREATE',
				restaurantId,
				day: obj.day,
				hour: Number(obj.hour),
				minute: Number(obj.minute),
			})
			if (errors) {
				handleMutationError('updateRestaurantSlot', errors)
			}
		},
		[restaurantId]
	)

	const handleOpenCreateDialog = React.useCallback(async () => {
		Dialog.render({
			renderContent: ({ dismissPortal }) => (
				<CreateDialog
					title="Neuen Slot erstellen"
					config={config}
					onSubmit={handleCreate}
					dismissPortal={dismissPortal}
				/>
			),
		})
	}, [handleCreate])

	const sortedSlots = React.useMemo(() => {
		return slots
			.slice()
			.sort((a, b) =>
				dayOrder[a.day] > dayOrder[b.day]
					? 1
					: dayOrder[a.day] === dayOrder[b.day]
					? a.hour > b.hour
						? 1
						: a.minute > b.minute
						? 1
						: -1
					: -1
			)
			.map((s) => ({ title: `${s.day} ${s.hour}:${s.minute === 0 ? '00' : s.minute}`, ...s }))
	}, [slots])

	return (
		<>
			<ContentWrapper p="1rem">
				<Box maxWidth="100%" m="1rem">
					{!slots || !slots.length ? null : (
						<EditableTable
							objects={sortedSlots}
							dict={dict}
							onDelete={handleDelete}
							onCreate={handleOpenCreateDialog}
						/>
					)}
				</Box>
			</ContentWrapper>
		</>
	)
}

export default EditRestaurantSlots
