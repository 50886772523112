import client from '../utils/client'
import { gql } from '@apollo/client'
import { MutationHandlerErrorResult, MutationHandlerSuccessResult } from '../utils/errors'

const login = async (
	email: string,
	password: string
): Promise<
	| (MutationHandlerErrorResult & { jwt: undefined })
	| (MutationHandlerSuccessResult & { jwt: string })
> => {
	try {
		const { data, errors } = await client.mutate({
			mutation: gql`
				mutation Login($email: String!, $password: String!, $privileged: Boolean) {
					login(email: $email, password: $password, privileged: $privileged) {
						success
						jwt
					}
				}
			`,
			variables: { email, password, privileged: true },
			errorPolicy: 'all',
		})
		if (errors) {
			return {
				success: false,
				errors,
				jwt: undefined,
			}
		}
		return {
			success: true,
			jwt: data.login.jwt,
			errors: null,
		}
	} catch (e) {
		if (e && e.networkError) return { success: false, errors: 'NETWORK_ERROR', jwt: undefined }
		throw e
	}
}

export default login
