import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, CircularProgress } from '@mui/material'

const ContentWrapper = ({
	children,
	maxWidth,
	center,
	minHeight,
	p,
}: {
	children?: JSX.Element | JSX.Element[] | (JSX.Element | null)[] | null | undefined
	maxWidth?: string
	center?: boolean
	minHeight?: string
	p?: string
}): JSX.Element => {
	const { contentMaxWidthPx, responsiveRem } = useTheme()
	const theme = useTheme()
	return (
		<Box
			minHeight={!children ? '10rem' : minHeight}
			flex="1"
			width="100%"
			display="flex"
			flexDirection="column"
			alignItems="center"
			bgcolor={theme.customColors.lightBackground}
		>
			<Box
				width="100%"
				flex="1"
				display="flex"
				flexDirection="column"
				maxWidth={maxWidth ? maxWidth : `${contentMaxWidthPx}px`}
				justifyContent={!children || center ? 'center' : 'flex-start'}
				alignItems={!children || center ? 'center' : 'flex-start'}
				p={responsiveRem(p)}
			>
				<>{!children ? <CircularProgress /> : children}</>
			</Box>
		</Box>
	)
}

export default React.memo(ContentWrapper)
