import { Box } from '@mui/material'
import React from 'react'
import Button from '../components/Button'
import ContentWrapper from '../components/ContentWrapper'
import Dialog from '../components/Dialog'
import SimpleTable, { SimpleTableObject } from '../components/SimpleTable'
import TextField from '../components/TextField'
import Txt from '../components/Txt'

type Item = SimpleTableObject & { title: string }

const EditDialogContent = ({
	title,
	item,
	editableKeys,
	onSubmit,
	dismissPortal,
	dict,
}: {
	title: string
	item: Item
	editableKeys: string[]
	onSubmit: (obj: any) => Promise<void>
	dismissPortal: () => void
	dict: Record<string, string>
}) => {
	const [loading, setLoading] = React.useState(false)
	const [state, setState] = React.useState(item)
	const handleChange = React.useCallback((value, id) => {
		setState((prev) => ({ ...prev, [id]: value }))
	}, [])

	const handleSave = React.useCallback(async () => {
		setLoading(true)
		await onSubmit(state)
		setLoading(false)
		dismissPortal()
	}, [dismissPortal, onSubmit, state])

	return (
		<ContentWrapper>
			<Box display="flex" flexDirection="column" width="30rem" p="1rem 2rem 2rem 2rem">
				<Box display="flex" flexDirection="row" p="1rem" justifyContent={'center'}>
					<Txt variant="h4">{title}</Txt>
				</Box>
				{Object.entries(item)
					.filter(([key]) => editableKeys.includes(key))
					.filter(([_, value]) => typeof value === 'number' || typeof value === 'string')
					.map(([key, value]) => (
						<TextField
							key={key}
							label={dict[key]}
							m="1rem 0"
							defaultValue={value as string | number}
							id={key}
							onChange={handleChange}
							type={typeof value === 'number' ? 'number' : 'text'}
						/>
					))}
				<Box display="flex" flexDirection="row" justifyContent="flex-end" m="1rem 0 0 0">
					<Button variant="outlined" m="0 1rem 0 0" onClick={dismissPortal} loading={loading}>
						Schließen
					</Button>
					<Button variant="outlined" m="0 0 0 0" onClick={handleSave} loading={loading}>
						Speichern
					</Button>
				</Box>
			</Box>
		</ContentWrapper>
	)
}

const EditableTable = ({
	objects,
	dict,
	onEdit,
	onDelete,
	onCreate,
	onConfirm,
}: {
	objects: Item[]
	dict: Record<string, string>
	onEdit?: (obj: Item) => Promise<void>
	onDelete?: (obj: Item) => Promise<void>
	onCreate?: (obj: Item) => Promise<void>
	onConfirm?: (obj: Item) => Promise<void>
}): JSX.Element => {
	const activeKeys = React.useMemo(() => {
		return Object.keys(dict)
	}, [dict])

	const editableKeys = React.useMemo(
		() => activeKeys.filter((key) => key !== 'title'),
		[activeKeys]
	)

	const handleEdit = React.useCallback(
		(id) => {
			const obj = objects.find((c) => c.id === id)
			Dialog.render({
				isLocked: true,
				renderContent: ({ dismissPortal }) => (
					<EditDialogContent
						title={obj.title}
						item={obj}
						editableKeys={editableKeys}
						onSubmit={onEdit}
						dismissPortal={dismissPortal}
						dict={dict}
					/>
				),
			})
		},
		[dict, editableKeys, objects, onEdit]
	)

	const handleDelete = React.useCallback(
		(id) => {
			const obj = objects.find((c) => c.id === id)
			Dialog.render({
				isLocked: true,
				title: 'Löschen',
				description: `Möchtest du ${obj.title} wirklich löschen?`,
				buttons: [
					{
						label: 'Abbrechen',
						id: 'cancel',
						variant: 'outlined',
					},
					{
						label: 'Löschen',
						onClick: async () => {
							await onDelete(obj)
						},
						variant: 'outlined',
						id: 'delete',
					},
				],
			})
		},
		[objects, onDelete]
	)

	const handleConfirm = React.useCallback(
		(id) => {
			const obj = objects.find((c) => c.id === id)
			onConfirm(obj)
		},
		[objects, onConfirm]
	)

	const renderActions = React.useCallback(
		(id) => (
			<Box display="flex" flexDirection="row">
				{onEdit ? (
					<Button variant="outlined" onClick={handleEdit} id={id} iconName="EditRounded" />
				) : null}
				{onDelete ? (
					<Button variant="outlined" onClick={handleDelete} id={id} iconName="Delete" />
				) : null}
				{onCreate ? <Button variant="outlined" onClick={onCreate} id={id} iconName="Add" /> : null}
				{onConfirm ? (
					<Button variant="outlined" onClick={handleConfirm} id={id} iconName="Check" />
				) : null}
			</Box>
		),
		[handleConfirm, handleDelete, handleEdit, onConfirm, onCreate, onDelete, onEdit]
	)

	return (
		<>
			<SimpleTable
				objects={objects}
				activeKeys={activeKeys}
				dict={dict}
				renderActions={onEdit || onDelete || onCreate || onConfirm ? renderActions : undefined}
			/>
		</>
	)
}

export default EditableTable
